import { Box, CircularProgress, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateTime } from 'luxon'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import { TODAY, TOMORROW } from '@/constants/dateTime'
import { useAlertContext } from '@/contexts/AlertContext'
import { useCreateSpotOnScheduleMutation } from '@/features/resource/hooks/useCreateSpotOnScheduleMutation'
import type { ApiScheduledResource } from '@/features/resource/types/spotOnForEboilers'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerScheduleFormProps = {
  scheduledResource: ApiScheduledResource | null
}

function SpotOnEboilerScheduleForm({ scheduledResource }: Readonly<SpotOnEboilerScheduleFormProps>) {
  const [selectedActiveDay, setSelectedActiveDay] = useState<DateTime | null>(TOMORROW)
  const { i18n, t } = useTranslation()
  const resourceId = scheduledResource?.resourceId ?? ''
  const navigate = useNavigate()

  const { createSchedule, isPending } = useCreateSpotOnScheduleMutation()
  const { pushAlert } = useAlertContext()

  const form = useForm()

  async function handleSubmit() {
    try {
      const response = await createSchedule({
        resourceId,
        activeDay: selectedActiveDay?.toFormat('yyyy-MM-dd') ?? '',
      })

      pushAlert({
        message: t('spot_on_for_eboilers.schedule_create.created_success_message'),
        severity: 'success',
      })

      navigate(`/resources/${resourceId}/spot-on/schedules/${response.activeDay}`, { replace: true })
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.schedule_create.created_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  if (!scheduledResource) {
    return (
      <Box alignItems="center" display="flex" height="200px" justifyContent="center">
        <CircularProgress title="Loading" />
      </Box>
    )
  }

  return (
    <Box paddingTop={2}>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
          <Stack gap={1}>
            <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  format="DD"
                  label={t('spot_on_for_eboilers.schedule_create.active_day')}
                  maxDate={TODAY.plus({ days: 3 })}
                  minDate={TODAY}
                  value={selectedActiveDay}
                  onChange={setSelectedActiveDay}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>

          <Stack direction="row" marginTop={3}>
            <CustomButton disabled={isPending} type="submit" variant="contained">
              {t('common.button.save')}
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  )
}

export default SpotOnEboilerScheduleForm
